.tableContainer {
  background-color: #1f253d;
  color: white;
}

.tableHeader {
  color: white;
  background-color: #2e3548; /* Adjust as needed */
  font-weight: bold;
  font-size: 1rem;
}

.tableCell {
  color: white;
}

.alarmType {
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.alarmType.low {
  color: #ffd700;
}

.alarmType.high {
  color: #ff0000;
}

.noAlarmHistory {
  color: white;
}

.pagination {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
}

.MuiTablePagination-selectIcon {
  color: white;
}

.MuiTablePagination-input {
  color: white;
}

.MuiTablePagination-actions {
  color: white;
}

.MuiTablePagination-caption {
  color: white;
}

/* Remove the default focus outline */
.MuiTablePagination-root button:focus {
  outline: none;
}

.tableContainer .MuiTableCell-root {
    border-bottom: none;
  }

.MuiTableRow-root:nth-of-type(even) {
    background-color: rgba(255, 255, 255, 0.05);
  }
