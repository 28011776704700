.qr-scanner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 0;
}

.qr-scanner-window {
  position: relative;
  width: 300px; /* Adjust the size of the scanner window as needed */
  height: 300px;
  border: 3px groove #5c9cfe;
  overflow: hidden;
  margin: 0;
  border-radius: 15px;
}

.scan-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the height of the scanning line */
  background-color: #00f; /* Adjust the color of the scanning line */
  animation: scanAnimation 2s linear infinite;
}

@keyframes scanAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.placeholder-box {
  position: relative;
  width: 300px; /* Adjust the size of the scanner window as needed */
  height: 300px;
  border: 3px groove #5c9cfe;
  overflow: hidden;
  margin: 0;
  border-radius: 15px;
  background-color: darkgrey;
}
